import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// import reportWebVitals from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
//   if ((onPerfEntry != null) && onPerfEntry instanceof Function) {

//     import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
//       getCLS(onPerfEntry)
//       getFID(onPerfEntry)
//       getFCP(onPerfEntry)
//       getLCP(onPerfEntry)
//       getTTFB(onPerfEntry)
//     })
//   }
// }
